<template>
  <div class='template'>    
      <div class='top'>        
        <article class="images">            
            <transition name="fade" appear>
                <div :style="{backgroundImage:`url(${page?.background})`}" />         
            </transition>
        </article>    
      </div>
      <article class='text-content'>  
          <img src="@/assets/images/placeholder_logo_car-asphalt.png" alt="" class='track-logo'>           
          <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
          <div class='text-content-limit-width'>            
            <ImageTitle transparent :title="page.title"/>  
            <transition name="slide" appear>
                <div>
                    <div>                        
                        <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
                    </div>                   
                </div>
            </transition>
            <div class="linear-holder">
                <LinearButton :to="page?.previous" previous/>
                <LinearButton :to="page?.next"/>
            </div>          
          </div>          
      </article>      
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Track Logo Text Box',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:0,
            copy:this.page.copy                
        }
    },
    computed: {   
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {
        display:grid;
        align-items: center;   
        position: relative;                    
        background:url('~@/assets/images/bg_texture.jpg');  
        background-size: cover;        
        box-sizing: border-box;
        @media (max-width:899px) {            
            grid-template-rows: minmax(200px, 50vh) minmax(50vh, max-content);
        }   
        @media (min-width:900px) {
            padding:25px;
            padding:130px;
            gap:25px;
            grid-template-rows: 1fr 300px;            
        }                   
    }    
    .top {
        height:100%;
    }
    .ui {               
        top:0;
        background:black;
        margin:0;
        padding:15px 25px;
        
        @media (min-width:900px) {
            position: absolute; 
            right:25px;
        }
    }
    .track-logo {
        width:100%;
        @media (max-width:899px) { 
            height: 100px;
            width: 100px;
            object-fit: contain;
            object-position: right;     
        }
    }
    .text-content {
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #181818;
        height:100%;
        position: relative;          
        gap:25px;    
        @media (min-width:900px) {    
            display:grid;
            grid-template-columns: 295px 1fr;
        }
        @media print {
            display:grid;
            grid-template-rows: 100px 1fr;
        }
        .copy {
            margin-top:18px;
            margin-bottom: 40px;
        }    
        .title {
            text-transform: uppercase;
            color: #181818;
            padding:0;
        } 
        .text-content-limit-width {
            padding:30px;
            box-sizing: border-box;     
            background: url('~@/assets/images/bg_cars-white.jpg');
            background-size: cover;
            padding-top:70px;     
        }
         @media (max-width:899px) {
            &:before {
                content:"";
                display:block;
                height:15px;
                width:100%;
                background-image:url('~@/assets/images/texture_black-asphalt.jpg');
                background-size: cover;            
                -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
                color-adjust: exact;     
            }       
        }       
        @media (min-width:900px) {
            height:100%;
            padding-top:0;     
            align-items: flex-start;
            justify-items: center;   
            grid-template-rows: 1fr;               
            .text-content-limit-width {
                margin:0 auto;
                height:100%;
                width:100%;
            }
            .text-content-limit-width > div {                
                > div:not(.copy) {                    
                    padding-right:0;
                    width:100%;
                }
            
            }
        }                            
    }   
    .images {        
        display:grid;
        gap:1px;               
        height:100%;  
        width:100%;  
        position: relative;              
        div {
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            background-repeat:no-repeat;
            background-size: cover;
            background-position: center;                                    
        }                  
    }   
</style>